import React from "react"
import { Div, Text, Image } from "atomize"

import PortableText from "../common/PortableText"

export default function FeaturesBlock({ title, body, icon }) {
  return (
    <Div d="flex">
      <Div style={{ minWidth: "80px", height: "80px" }} m={{ r: "1.25rem" }}>
        <Image src={icon.asset.url} h="100%" w="100%" />
      </Div>
      <Div textAlign="left">
        <Text
          textWeight="bold"
          textSize="header"
          style={{ lineHeight: "16px" }}
        >
          {title}
        </Text>
        <Text m={{ t: "0.625rem" }}>
          <PortableText blocks={body} />
        </Text>
      </Div>
    </Div>
  )
}

import React from "react"
import {Helmet} from "react-helmet"

export const SEO = ({title, description, keywords, media, url, socialTitle, socialDesc, productInfo}) => {
  const siteMetadata = {
    title: `Not Pot`,
    description: `We’ve created a new approach to wellness—one that’s safe, effective, and serious fun. Meet the everyday essentials designed to grow with you.`,
    author: `@notpot`,
    image: 'https://cdn.shopify.com/s/files/1/2229/0673/files/OG-img.jpg?v=1605249136',
    keywords: ['CBD gummies', 'CBD oil', 'CBD store', 'Vegan CBD gummies']
  }
  const metaDescription = description || siteMetadata.description;
  const metatitle = title || siteMetadata.title;
  const metaImage = media || siteMetadata.image;
  const metaSocialTitle = socialTitle || metatitle;
  const metaSocialDesc = socialDesc || metaDescription;
  const metaKeywords = keywords || [];
  const siteAuthor = siteMetadata.author;
  const seoUrl = url ? url : 'https://notpot.com/';
  const productMeta = [
    {name: 'product:item_group_id', content: productInfo?.productGroupId, value: productInfo?.productGroupId},
    {name: 'product:retailer_item_id', content: productInfo?.productId, value: productInfo?.productId},
    {name: 'product:brand', content: 'Not Pot'},

    {name: 'product:availability', content: productInfo?.quantity ? 'in stock' : 'out of stock', value: 'in stock'},
    {name: 'product:condition', content: 'new', value: 'new'},
    {name: 'product:price:amount', content: productInfo?.price, value: productInfo?.price},
    {name: 'product:price:currency', content: 'USD'},
    {name: 'og:price:currency', content: 'USD'},
    {name: 'og:price:amount', content: productInfo?.price, value: productInfo?.price},
    {name: 'price', value: `${productInfo?.price} USD`,},
    {name: 'content_id', content: productInfo?.productId},
    {name: 'id', content: productInfo?.productId, value: productInfo?.productId},
    {name: 'availability', content: 'in stock', value: 'in stock'},
    {name: 'google_product_category', content: 'Product'}
  ];
  const metaData = [
    {
      name: "google-site-verification",
      content: "7MfJFsxBVui5UlEBExUFeMW9-Q6g9fPgoaxwzvbqaV0",
    },
    {
      name: "description",
      content: metaDescription,
    },
    {
      property: "og:title",
      content: metaSocialTitle,
    },
    {
      property: "og:image",
      content: metaImage,
    },
    {
      property: "og:description",
      content: metaSocialDesc,
    },
    {
      property: "fb:app_id",
      content: "1119211085164333",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:url",
      content: seoUrl,
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:creator",
      content: siteAuthor,
    },
    {
      name: "twitter:title",
      content: metaSocialTitle,
    },
    {
      name: "twitter:description",
      content: metaSocialDesc,
    },
    {
      name: "twitter:image",
      content: metaImage,
    },
    {
      property: "twitter:url",
      content: seoUrl,
    },
  ].concat(metaKeywords && metaKeywords.length > 0 ? {
    name: "keywords",
    content: metaKeywords.join(", "),
  } : [])
    .concat(productMeta);
  return (
    <Helmet title={metatitle} meta={metaData}>
      <script type="application/ld+json">
        {`
        {
          "og": {
            "price":{
              "currency":"USD"
              "amount":"40.00"
            }
          },
          "product":{
          "brand":"Not Pot"
          "availability":"in stock"
          "condition":"new"
          "item_group_id":"212313312"
          "retailer_item_id":"1234556612331"
          "price":{
              "currency":"USD"
              "amount":"40.00"
            }
          }
        }
      `}
      </script>
    </Helmet>
  )
}
